import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React, { useState } from "react";
import SectionsHeading from "../../MainComponent/SectionsHeading";
import Paragraph from "../../MainComponent/Paragraph";
import roadmapBg from "../../../assets/Images/roadmapBg.jpg";
import { FlexGrid } from "../../Styles/style";

import largePath from "../../../assets/Images/largePath.png";
const ContinerWraper = styled(Container)`
  width: 100%;
  background-image: url(${roadmapBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding: 20px 0 !important;
  @media (max-width: 599px) {
    background-position: center;
  }
`;
const ColoredDivs = styled.div`
  background-image: url(${largePath});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 40px;
  width: 100%;
  min-height: 200px;
`;
const ColoredDivSmal = styled.div`
  padding: 10 15px;
  transition: 0.6s ease-in-out;
`;

const FAQSection = () => {
  const [selected1, setSelected1] = useState("selected");
  const [selected2, setSelected2] = useState("not");
  const [selected3, setSelected3] = useState("not");
  const handleMouseEnter1 = () => {
    setSelected1("selected");
    setSelected2("not");
    setSelected3("not");
  };
  const handleMouseEnter2 = () => {
    setSelected1("not");
    setSelected2("selected");
    setSelected3("not");
  };
  const handleMouseEnter3 = () => {
    setSelected1("not");
    setSelected2("not");
    setSelected3("selected");
  };
  return (
    <ContinerWraper maxWidth="xxl" id="roadmap">
      <Container sx={{ width: "100%", minHeight: "calc(100vh - 100px)" }}>
        <Grid
          container
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            width: "100%",
            minHeight: "calc(100vh - 100px)",
            gap: "20px",
          }}
        >
          <FlexGrid item xs={12} data-aos="zoom-in">
            <SectionsHeading ta="left">
              RoadMap
            </SectionsHeading>
          </FlexGrid>
          <FlexGrid item xs={12} g="20px" data-aos="fade-up">
            <ColoredDivSmal
              onMouseEnter={() => {
                handleMouseEnter1();
              }}
              className={selected1}
            >
              <Paragraph
                color={selected1 === "selected" ? "#fff" : "#000"}
                fs="22px"
                p="7px 20px"
              >
                PHASE 1
              </Paragraph>
            </ColoredDivSmal>
            <ColoredDivSmal
              onMouseEnter={() => {
                handleMouseEnter2();
              }}
              className={selected2}
            >
              <Paragraph
                color={selected2 === "selected" ? "#fff" : "#000"}
                fs="22px"
                p="7px 20px"
              >
                PHASE 2
              </Paragraph>
            </ColoredDivSmal>
            <ColoredDivSmal
              onMouseEnter={() => {
                handleMouseEnter3();
              }}
              className={selected3}
            >
              <Paragraph
                color={selected3 === "selected" ? "#fff" : "#000"}
                fs="22px"
                p="7px 20px"
              >
                PHASE 3
              </Paragraph>
            </ColoredDivSmal>
          </FlexGrid>
          <FlexGrid
            data-aos="zoom-in"
            sx={{ borderRadius: "20px" }}
            p="20px"
            fd="column"
            jc="start"
            ai="start"
            item
            xs={12}
            sm={6}
          >
            <ColoredDivs>
              {selected1 === "selected" ? (
                <Paragraph ta="center" fm="PoppinsMI">
                  Website & contract launch <br /> Initial community building{" "}
                  <br /> Dextools update <br /> CG and CMC listings <br /> 1000
                  Holders <br />
                </Paragraph>
              ) : selected2 === "selected" ? (
                <Paragraph ta="center" fm="PoppinsMI">
                  Banner ads <br /> Exchange Listings <br /> Partnership
                  Announcments <br /> CG and CMC listings <br /> 5000 Holders{" "}
                  <br />
                </Paragraph>
              ) : (
                <Paragraph ta="center" fm="PoppinsMI">
                  Dog Get Token back <br /> Merch store <br /> NFT collection{" "}
                  <br /> 10,000 Holders <br />
                </Paragraph>
              )}
            </ColoredDivs>
          </FlexGrid>
        </Grid>
      </Container>
    </ContinerWraper>
  );
};

export default FAQSection;
