import React from "react";
import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import SectionsHeading from "../../MainComponent/SectionsHeading";
import Paragraph from "../../MainComponent/Paragraph";
import aboutBg from "../../../assets/Images/aboutBg.jpg";
import largePath from "../../../assets/Images/largePath.png";
const ContinerWraper = styled(Container)`
  width: 100%;
  background-image: url(${aboutBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding: 20px 0 !important;
  @media (max-width: 599px) {
    background-position: right;
  }
`;

const ColoredDivs = styled.div`
  background-image: url(${largePath});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 40px;
  width: 100%;
  height: 100%;
`;

const AboutSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="story">
        <Container sx={{ width: "100%", minHeight: "100vh" }}>
          <Grid
            container
            sx={{
              display: "flex",
              alignContent: "start",
              justifyContent: "start",
              width: "100%",
              minHeight: "100vh",
              gap: "20px",
            }}
          >
            <Grid item xs={12} sm={6} data-aos="zoom-in">
              <ColoredDivs>
                <SectionsHeading color="#fff" ta="left">
                  Story
                </SectionsHeading>
                <Paragraph  ta="left" fm="PoppinsMI">
                  Once upon a time, in a world where dogs were known for their
                  elegance and grace, there lived a rather unfortunate dog named
                  Spike. Spike had a peculiar problem – he had eaten something
                  funky, and now he couldn't stop letting out noisy, odorous
                  eruptions that could be heard from miles away. His owner, a
                  cryptocurrency enthusiast with a heart as big as a Saint
                  Bernard's, was determined to help him. <br />
                  Spike's flatulence was legendary in the neighborhood, and it
                  had even disrupted important community gatherings. Desperate
                  for a solution, the owner hatched a brilliant plan – to create
                  "Dogfart Tokens" to fund Spike's much-needed cure!
                </Paragraph>
              </ColoredDivs>
            </Grid>
          </Grid>
        </Container>
      </ContinerWraper>
      
    </>
  );
};

export default AboutSection;
