import styled from "@emotion/styled";
import { Container, Grid, Link } from "@mui/material";
import React from "react";
import { FlexGrid, Image } from "../../Styles/style";
import Paragraph from "../../MainComponent/Paragraph";
import SectionsHeading from "../../MainComponent/SectionsHeading";
import dex from "../../../assets/Images/dex.png";
import x from "../../../assets/Images/x.png";
import tg from "../../../assets/Images/tg.png";
import uniswap from "../../../assets/Images/uniswap.png";
import heroBg from "../../../assets/Images/heroBg.mp4";
import smalPath from "../../../assets/Images/smalPath.png";
const CntainerWraper = styled(Container)`
  width: 100%;
  min-height: 100vh;
  padding: 0px !important;
  position: relative;
  @media (max-width: 599px) {
    padding: 0px !important;
  }
`;
const GridWraper = styled(Grid)`
  padding-top: 20px;
  @media (max-width: 599px) {
    flex-direction: ${(props) => props.fd599};
  }
`;
const VIDEO = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: -1;
`;
const NavColoredDivs = styled.div`
  background-image: url(${smalPath});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 7px 15px;
`;
const HeroSection = ({ isPlaying, setIsPlaying }) => {
  const playAudio = () => {
    setIsPlaying(!isPlaying);
  };
  return (
    <CntainerWraper maxWidth="xxl" id="home">
      <VIDEO muted loop autoPlay>
        <source src={heroBg} />
      </VIDEO>
      {/* <LockerMenu /> */}

      <Container
        sx={{
          position: "relative",
          height: "calc(100vh)",
          display: "flex",
          alignItems: "start",
        }}
      >
        <GridWraper container>
          <FlexGrid
            container
            item
            xs={12}
            g="0"
            sx={{ rowGap: "0px !important", columnGap: "20px !important" }}
          >
            <Link sx={{ textDecoration: "none" }} href="#story">
              <NavColoredDivs>
                <Paragraph ts="0px" fs="18px" p="10px 15px">
                  Story
                </Paragraph>
              </NavColoredDivs>
            </Link>
            <Link sx={{ textDecoration: "none" }} href="#roadmap">
              <NavColoredDivs>
                <Paragraph ts="0px" fs="18px" p="7px 15px">
                  Roadmap
                </Paragraph>
              </NavColoredDivs>
            </Link>
            <Link sx={{ textDecoration: "none" }} href="#tokenomics">
              <NavColoredDivs>
                <Paragraph ts="0px" fs="18px">
                  Tokenomics
                </Paragraph>
              </NavColoredDivs>
            </Link>
            <Link
              sx={{ textDecoration: "none", cursor: "pointer" }}
              onClick={() => playAudio()}
            >
              <NavColoredDivs>
                <Paragraph ts="0px" fs="18px" p="10px 15px">
                  {isPlaying ? "Mute" : "PLAY"}
                </Paragraph>
              </NavColoredDivs>
            </Link>
          </FlexGrid>
          <FlexGrid
            container
            item
            ai="center"
            jc="center"
            p599="0 5px"
            sx={{ alignContent: "center" }}
            xs={12}
          >
            <FlexGrid
              g="0"
              // p="50px 50px 0 0"
              p599="0"
              fd="column"
              ai="center"
              jc="center"
              item
              xs={12}
            >
              <SectionsHeading
                fs="100px"
                fs599="70px"
                ta="center"
                data-aos="zoom-in"
              >
                DOG FART
              </SectionsHeading>
              <Paragraph fs="22px" color="#000" ta="center" data-aos="fade-up" sx={{wordBreak:"break-all"}}>
                Ca: 0xA9Bd591529cea30FC3815fc130F67E38073E175A
              </Paragraph>
            </FlexGrid>
            <FlexGrid
              g="10px"
              ai="center"
              jc="center"
              item
              xs={12}
              data-aos="fade-up"
            >
              <Link href="https://twitter.com/dogfart_eth" target="blank">
                <Image mw="40px" src={x} />
              </Link>
              <Link href="https://t.me/dogfart_eth" target="blank">
                <Image mw="40px" src={tg} />
              </Link>
              <Link href="https://www.dextools.io/app/en/ether/pair-explorer/0xA9Bd591529cea30FC3815fc130F67E38073E175A" target="blank">
                <Image mw="40px" src={dex} />
              </Link>
              <Link href="https://app.uniswap.org/swap?outputCurrency=0xA9Bd591529cea30FC3815fc130F67E38073E175A&chain=mainnet" target="blank">
                <Image mw="40px" src={uniswap} />
              </Link>
            </FlexGrid>
          </FlexGrid>
          {/* <FlexGrid className="hero" item xs={12} sm={7} data-aos="zoom-in">
            <Image className="image" mw="70%" mw599="100%" src={heroImage} />
          </FlexGrid> */}
        </GridWraper>
      </Container>
    </CntainerWraper>
  );
};

export default HeroSection;
