import styled from "@emotion/styled";
import { Grid } from "@mui/material";

import smalPath from "../../assets/Images/smalPath.png";
const PageWrapper = styled.div`
  background-color: transparent;
  overflow: hidden;
  /* background: radial-gradient(circle at 50% 60%, #1177f37a, transparent 80%); */

  min-height: 100vh;
`;
const PageWrapper1 = styled.div`
  background: radial-gradient(circle at 50% 50%, #1177f37a, transparent 65%);
  min-height: 100vh;
`;
const PageWrapper2 = styled.div`
  background: radial-gradient(circle at 50% 100%, #1177f37a, transparent 50%);
`;

const Image = styled.img`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "30px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  position: relative;
  z-index: 999;
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    margin: ${(props) => (props.m599 ? props.m599 : "0")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  top: ${(props) => (props.t ? props.t : "30%")};
  left: ${(props) => (props.l ? props.l : "50%")};
  transform: ${(props) => (props.tran ? props.tran : "translate(-50%,-30%)")};
  max-width: ${(props) => (props.mw ? props.mw : "400px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  opacity: ${(props) => props.op};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (min-width: 1399px) {
    max-width: ${(props) => props.mw1399};
  }
  @media (max-width: 899px) {
    max-width: ${(props) => props.mw899};
    left: ${(props) => props.l899};
    top: ${(props) => props.t899};
    padding: ${(props) => (props.m ? props.m : "0 0px 0 0")};
    opacity: ${(props) => props.op899};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => props.mw599};
    left: ${(props) => props.l599};
    top: ${(props) => props.t599};
    transform: ${(props) => props.tran599};
    opacity: ${(props) => props.op599};
  }
`;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 899px) {
    flex-direction: ${(props) => props.fd899};
    row-gap: ${(props) => (props.fd899 ? "20px" : "")};
  }
`;
const GridWraper = styled(Grid)`
  width: 100%;
  min-height: calc(100vh - 90px);
  position: relative;
  display: flex;
  align-content: space-between;
  justify-content: center;
`;

const GradiantDiv = styled.div`
  display: flex;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: relative;
  border-radius: ${(props) => (props.br ? props.br : "5px")};
  row-gap: 10px;
  padding: ${(props) => (props.p ? props.p : "20px 30px 20px 20px")};
  margin: ${(props) => (props.m ? props.m : "20px 0")};
  width: ${(props) => (props.w ? props.w : "90%")};
  min-height: ${(props) => (props.h ? props.h : "300px")};
  border: 1px solid #1279f3;
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.bgGradient};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5;
  }
`;

const SndGradiantDiv = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: start;
  align-items: start;
  position: relative;
  border-radius: 20px;
  row-gap: 10px;
  padding: ${(props) => (props.p ? props.p : "20px 30px 20px 20px")};
  margin: 20px 0;
  width: ${(props) => (props.w ? props.w : "100%")};
  height: ${(props) => (props.h ? props.h : "100%")};
  ::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background-image: url(${(props) => props.bg});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    opacity: 0.5;
  }
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background: ${(props) => props.theme.bgGradient};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    opacity: 0.6;
  }
`;
const CardGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.ai ? props.ai : "start")};
  justify-content: ${(props) => (props.jc ? props.jc : "start")};
  width: 100%;
  background: linear-gradient(to top, #161616e0, #161616);
  padding: ${(props) => (props.p ? props.p : "20px")};
  border-radius: 20px;
  row-gap: 10px;
  min-height: ${(props) => (props.mh ? props.mh : "20vh")};
  z-index: 1;
`;

const FlexGrid = styled(Grid)`
  display: flex;
  width: ${(props) => props.w};
  flex-direction: ${(props) => (props.fd ? props.fd : "row")};
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  gap: ${(props) => (props.g ? props.g : "20px")};
  @media (max-width: 700px) {
    flex-direction: ${(props) => props.fd599};
    padding: ${(props) => props.p599};
    width: ${(props) => props.w599};
  }
`;
const ColumnGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
`;
const NavColoredDivs = styled.div`
  background-image: url(${smalPath});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 7px 15px;
  cursor: pointer;
`;
export {
  PageWrapper,
  CardGrid,
  Image,
  AbsoluiteImage,
  Div,
  GridWraper,
  GradiantDiv,
  SndGradiantDiv,
  FlexGrid,
  ColumnGrid,
  PageWrapper1,
  PageWrapper2,
  NavColoredDivs,
};
