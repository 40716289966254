import React from "react";
import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import SectionsHeading from "../../MainComponent/SectionsHeading";
import Paragraph from "../../MainComponent/Paragraph";
import tokenBg from "../../../assets/Images/tokenBg.jpg";
import tokenImage from "../../../assets/Images/tokenImage.png";
import { FlexGrid, Image } from "../../Styles/style";
const ContinerWraper = styled(Container)`
  width: 100%;
  background-image: url(${tokenBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  @media (max-width: 599px) {
    background-position: center;
  }
`;

const Tokenomics = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="tokenomics">
        <Container>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "30px",
            }}
          >
            <FlexGrid container item xs={12} sm={6}>
              <Grid item xs={12} sx={{ p: "50px 0 0" }} data-aos="zoom-in">
                <SectionsHeading color="#000" ta="left">
                  Tokenomics
                </SectionsHeading>
              </Grid>
              <FlexGrid item xs={12} jc="start" data-aos="fade-up">
                <Paragraph color="#000" ta="left" fm="PoppinsMI">
                  Buy & Sell tax will be set at 2% until 500k marketcap. Then
                  will be dropped to 0% forever.
                </Paragraph>
              </FlexGrid>
              <FlexGrid item xs={12} jc="start" data-aos="zoom-in">
                <Paragraph fs="22px" color="#000" ta="left">
                  Total supply <br />
                  <SectionsHeading>101010101010</SectionsHeading>
                </Paragraph>
              </FlexGrid>
              <FlexGrid item xs={12} jc="start" data-aos="fade-up">
                <Paragraph color="#000" ta="left" fm="PoppinsMI">
                  93.1% of supply will be used as liquidity, 6.9% of supply will
                  belong to our multi-sig marketing & cex wallet "TBA". <br />
                  Contract will be renounced, and LP will be locked after
                  launch.
                </Paragraph>
              </FlexGrid>
            </FlexGrid>
            <FlexGrid p="50px 0 0" container item xs={12} sm={5}>
              <FlexGrid item xs={12} jc="start" data-aos="zoom-in">
                <Image mw="80%" src={tokenImage} />
              </FlexGrid>
              <FlexGrid item xs={12} jc="start" data-aos="fade-up">
                <Paragraph color="#000" ta="left" fm="PoppinsMI">
                  - 93.1% Liquidity Pool <br />- 6.9% Multi-sig Marketing & CEX
                  wallet
                </Paragraph>
              </FlexGrid>
            </FlexGrid>
          </Grid>
        </Container>
      </ContinerWraper>
      <div
        style={{
          background: "#000",
          padding: "10px 0",
        }}
      >
        <SectionsHeading fs="26px" color="#fff">
          dogfart.finance
        </SectionsHeading>
      </div>
    </>
  );
};

export default Tokenomics;
