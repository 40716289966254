import React, { useState } from "react";
import { NavColoredDivs, PageWrapper } from "../../Styles/style";
import HeroSection from "../../Sections/S1-Header";
import Roadmap from "../../Sections/S3-Roadmap";
import AboutSection from "../../Sections/S2-Story";
import Tokenomics from "../../Sections/S7-Tokenomics";
import music from "../../../assets/music.mp3";
import story from "../../../assets/Images/story.mp4";
import welcomBg from "../../../assets/Images/welcomBg.jpg";
import smalPath from "../../../assets/Images/smalPath.png";
import smalPathWhite from "../../../assets/Images/smalPathWhite.png";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import SectionsHeading from "../../MainComponent/SectionsHeading";
import Paragraph from "../../MainComponent/Paragraph";

const CntainerWraper1 = styled(Container)`
  width: 100%;
  min-height: 100vh;
  background-image: url(${welcomBg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px !important;
  position: relative;
  @media (max-width: 599px) {
    padding: 0px !important;
  }
`;
const CntainerDiv = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #000000f2;
  padding: 0px !important;
  position: absolute;
  top: 0%;
  left: 0%;
  @media (max-width: 599px) {
    padding: 0px !important;
  }
`;
const CntainerWraper = styled(Container)`
  width: 100%;
  min-height: 100vh;
  padding: 0px !important;
  position: relative;
  @media (max-width: 599px) {
    padding: 0px !important;
  }
`;
const VIDEO = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: -1;
  @media (max-width: 599px) {
    object-fit: contain;
    background-color: #000;
  }
`;
const ColoredDiv = styled.div`
  background-image: url(${smalPath});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 7px 15px;
  cursor: pointer;
  position: absolute;
  bottom: 5%;
  right: 5%;
  @media (max-width: 599px) {
    background-image: url(${smalPathWhite});
  }
`;
const MainPage = (props) => {
  const [isWelcome, setIsWelcome] = useState(false);
  const [skip, setSkip] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [timerId, setTimerId] = useState(null);

  const startTimeout = () => {
    const timeoutId = setTimeout(() => {
      // Your code to execute after 42 seconds
      setSkip(true);
      setIsPlaying(true);
    }, 45000); // 42 seconds in milliseconds
    setTimerId(timeoutId);
  };

  const cancelTimeout = () => {
    if (timerId) {
      clearTimeout(timerId);
      setTimerId(null);
      setSkip(true);
      setIsPlaying(true);
    }
  };
  const handleStory = () => {
    setIsWelcome(true);
    startTimeout();
  };
  const handleSkip = () => {
    setIsPlaying(true);
    setIsWelcome(true);
    setSkip(true);
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Your code to execute after 42 seconds
      console.log("42 seconds have passed!");
    }, 42000); // 42 seconds in milliseconds

    // Clear the timeout if the component unmounts or the effect is re-run
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  });
  return (
    <PageWrapper>
      {isWelcome ? (
        <>
          {skip ? (
            <>
              <HeroSection isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
              <AboutSection />
              <Roadmap />
              <Tokenomics />
            </>
          ) : (
            <CntainerWraper maxWidth="xxl">
              <VIDEO autoPlay>
                <source src={story} type="video/mp4" />
              </VIDEO>
              <ColoredDiv
                onClick={() => {
                  cancelTimeout();
                }}
              >
                <Paragraph
                  fs="22px"
                  p="10px 20px"
                  sx={{ color: { xs: "#000", sm: "#fff" } }}
                >
                  SKIP
                </Paragraph>
              </ColoredDiv>
            </CntainerWraper>
          )}
        </>
      ) : (
        <>
          <CntainerWraper1 maxWidth="xxl">
            <CntainerDiv>
              <SectionsHeading fs="60px" color="#fff">
                How Did It Come To This?
              </SectionsHeading>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px 0",
                  width: "100%",
                }}
              >
                <NavColoredDivs
                  onClick={() => {
                    handleStory();
                  }}
                >
                  <Paragraph fs="22px" p="10px 20px">
                    STORY
                  </Paragraph>
                </NavColoredDivs>
                <NavColoredDivs
                  onClick={() => {
                    handleSkip();
                  }}
                >
                  <Paragraph fs="22px" p="10px 20px">
                    SKIP
                  </Paragraph>
                </NavColoredDivs>
              </div>
            </CntainerDiv>
          </CntainerWraper1>
        </>
      )}
      {isPlaying && (
        <audio autoPlay loop>
          <source src={music} type="audio/mpeg" />
        </audio>
      )}
    </PageWrapper>
  );
};

export default MainPage;
